<template>
    <app-files-field
        v-model="valueLocal"
        :item-id="item.id"
        :parent-column-name="parentColumnName"
        label="Files"
        :can-open="!isSaving"
        :file-table-name="field.foreignKeyPascalSingular"
        v-on="$listeners" />
</template>

<script>
export default {
    components: {
        appFilesField: () => import("@/components/AppFilesField")
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isSaving: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: null,
        },
        schema: {
            type: Object,
            default: null,
        },
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        parentColumnName() {
            if (this.isEmpty(this.field)) {
                return "";
            }
            return this.schema.camelSingular + "Id";
        }
    },
};
</script>
